export class RetailLocationFilter {
	code: string;
	name: string;
	stationType: string;
	onlyValidLocation: boolean;
	onlyWithPrmFacilities: boolean;
	locationStatus: string;
	pageNumber: number;
	pageSize: number;
}
