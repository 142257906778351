import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@app/core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		public userService: UserService, public router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let expectedPermission = (route.data.expectedPermission == undefined) ? "" : route.data.expectedPermission;

		if (this.userService.isAuthenticated()) {
			if(expectedPermission != "" && !this.userService.hasPermission(expectedPermission)) {
				this.router.navigateByUrl(this.router.createUrlTree(['/login'], {queryParams: { 'returnUrl': state.url }}));
				return false;
			} else {
				return true;
			}
		} else {
			this.router.navigateByUrl(this.router.createUrlTree(['/login'], {queryParams: { 'returnUrl': state.url }}));
			return false;
		}
	}
}
