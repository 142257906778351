import { Component, OnInit } from "@angular/core";
import { CONTENT_MENU } from "@config/content-menu";
import { UserService } from "@app/core/services/user.service";
import { Router } from "@angular/router";
import { Country } from "@app/core/models/country.model";
import { SessionService } from "@app/core/services/session.service";
import { CountryService } from "@app/core/services/country.service";
import { RetailLocationFilter } from "@core/models/retail-location-filter.model";

@Component({
	selector: "app-private",
	templateUrl: "./private.component.html",
	styleUrls: ["./private.component.scss"],
})
export class PrivateComponent implements OnInit {
	mainMenu = null;
	userName: string = null;
	countries: Country[] = null;
	selectedCountry: Country = null;

	constructor(private userService: UserService, private router: Router, private sessionService: SessionService, private countryService: CountryService) {}

	ngOnInit() {
		let user = this.userService.getUser();
		if (user) {
			this.userName = this.userService.getUserName();
			this.countryService.getAllCountries().subscribe(
				(data) => {
					this.countries = data;
					this.selectedCountry = this.sessionService.getSelectedCountry();
				},
				(error) => {
					console.log(error);
				}
			);
		}
		this.mainMenu = [];
		for (let index = 0; index < CONTENT_MENU.length; index++) {
			if (CONTENT_MENU[index].expectedPermission == undefined || CONTENT_MENU[index].expectedPermission == null || this.userService.hasPermission(CONTENT_MENU[index].expectedPermission)) {
				this.mainMenu.push(CONTENT_MENU[index]);
			}
		}
	}

	logout() {
		this.sessionService.clearStorage();
		this.router.navigateByUrl("/login");
	}

	selectCountry(country: Country): void {
		this.selectedCountry = country;
		this.sessionService.saveSelectedCountry(country);
		if (this.router.url.includes("/retail-locations/edit/")) {
			let retailLocationFilter: RetailLocationFilter = new RetailLocationFilter();
			retailLocationFilter.code = "";
			retailLocationFilter.name = "";
			retailLocationFilter.stationType = "";
			retailLocationFilter.onlyValidLocation = true;
			retailLocationFilter.onlyWithPrmFacilities = false;
			retailLocationFilter.locationStatus = "";
			this.sessionService.saveRetailLocationFilter(retailLocationFilter);
			this.router.navigateByUrl("/retail-locations").then(function () {
				location.reload();
			});
		} else {
			location.reload();
		}
	}
}
