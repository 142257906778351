import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
//import { MatMomentDateModule } from '@angular/material-moment-adapter';

// Components
import { NavListComponent } from './components/nav-list/nav-list.component';
import { MatToolbarModule } from '@angular/material';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MeritsMapDialogComponent } from '@app/modules/master-data/components/merits-map-dialog/merits-map-dialog.component';

const MODULES = [
  CommonModule,
  RouterModule,
  TranslateModule,
  //MatMomentDateModule,
  MatCardModule, 
  MatNativeDateModule,
  FormsModule,
  ReactiveFormsModule,
  MatToolbarModule, 
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatButtonModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatDialogModule,
  MatProgressBarModule,
  MatSelectModule,
  MatDatepickerModule, 
  MatCheckboxModule, 
  MatChipsModule
];

const COMPONENTS = [
  NavListComponent, ConfirmationDialogComponent, AlertDialogComponent, MeritsMapDialogComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...MODULES
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS
  ],
  providers: [
    MatNativeDateModule,
    MatDatepickerModule
  ], 
  entryComponents: [
    ConfirmationDialogComponent, 
    AlertDialogComponent, 
    MeritsMapDialogComponent
  ],
})
export class SharedModule { }
