import { RetailLocationFilter } from './../models/retail-location-filter.model';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Country } from '@app/core/models/country.model';
import { DqmReportFilter } from '../models/dqm-report-filter.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

	constructor(
		private apiService: ApiService
	) {	}

	saveSelectedCountry(country: Country): void {
		localStorage.setItem('country', JSON.stringify(country));
	}

	getSelectedCountry(): Country {
        return Object.assign(new Country(), JSON.parse(localStorage.getItem('country')));
	}

	saveUserLanguage(userLanguage: string): void {
		localStorage.setItem('userLanguage', userLanguage);
	}

	getUserLanguage(): string {
		let userLanguage = localStorage.getItem('userLanguage');
		//if(userLanguage && userLanguage != '') {
		//	return userLanguage;
		//} else {
			return 'en';
		//}
	}

	clearStorage(): void {
		let userLanguage = this.getUserLanguage();
		sessionStorage.clear();
		localStorage.clear();
		this.saveUserLanguage(userLanguage);
	}
	saveRetailLocationFilter(retailLocationFilter: RetailLocationFilter): void {
		localStorage.setItem('retailLocationFilter', JSON.stringify(retailLocationFilter));
	}

	getRetailLocationFilter(): RetailLocationFilter {
        return Object.assign(new RetailLocationFilter(), JSON.parse(localStorage.getItem('retailLocationFilter')));
	}

	saveDqmReportFilter(dqmReportFilter: DqmReportFilter): void {
		localStorage.setItem('dqmReportFilter',JSON.stringify(dqmReportFilter));
	}

	getDqmReportFilter(): DqmReportFilter {
		return Object.assign(new DqmReportFilter(), JSON.parse(localStorage.getItem('dqmReportFilter')));
	}



}
