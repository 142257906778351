import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment as env } from 'environments/environment';
import { isArray } from 'util';
import { Country } from '@app/core/models/country.model';
import { User } from '@app/core/models/user.model';
import { Language } from '@app/core/models/language.model';
import { Role } from '@app/core/models/role.model';
import { NotificationEvent } from '@app/core/models/notification-event.model';
import { NotificationScope } from '@app/core/models/notification-scope.model';

const routes = {
	signin: 'auth/signin', 
	users: 'users/', 
	getAllLanguages: 'users/getAllLanguages', 
	getAllRoles: 'users/getAllRoles', 
	userProfile: 'profile/', 
	getAllNotificationEvents: 'users/getAllNotificationEvents', 
	getAllNotificationScopes: 'users/getAllNotificationScopes', 
	verifyNewEmailAddress: 'profile/verifyNewEmailAddress', 
	authorize: 'oauth/authorize'
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
	jwtHelperService: JwtHelperService = new JwtHelperService();

	constructor(
		private apiService: ApiService, 
		private http: HttpClient
	) {	}

	isAuthenticated(): boolean {
		let user = this.getUser();
		if(user && user.token) {
			if(!this.jwtHelperService.isTokenExpired(user.token)) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	hasPermission(permission:string):boolean {
		let hasPermission:boolean = false;
		let user = this.getUser();
		if(user) {
			let token = this.jwtHelperService.decodeToken(user.token);			
			if(token && token.authorities && isArray(token.authorities)) {
				token.authorities.forEach(element => {
					if(element.authority == permission){
						hasPermission = true;
					}
				});
			}
		}

		return hasPermission;
	}

	login(userName:string, password:string): Observable<any> {
		let options = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
		return this.http
			.post(env.baseUrl + routes.signin, 'username='+userName+'&password='+password, options)
			.pipe(catchError(this.apiService.handleError));
	}

	getUser(): any {
		return JSON.parse(localStorage.getItem('user'));
	}

	setUser(user): void {
		localStorage.setItem('user', JSON.stringify(user));
	}

	getUserName() {
		let user = this.getUser();
		if(user) {
			let token = this.jwtHelperService.decodeToken(user.token);
			if(token && token.name) {
				return token.name;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	getToken(): string {
		let user = this.getUser();
		if(user) {
			return user.token;
		} else {
			return null;
		}
	}

	getTokenDecoded(): any {
		let user = this.getUser();
		if(user) {
			let token = this.jwtHelperService.decodeToken(user.token);
			return token;
		} else {
			return null;
		}
	}

	getUserCountries(): Country[] {
		let user = this.getUser();
		if(user) {
			let token = this.jwtHelperService.decodeToken(user.token);
			if(token && token.userCountries && isArray(token.userCountries)) {
				return Object.assign(new Array<Country>(), token.userCountries);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	getUserLanguage(): string {
		let user = this.getUser();
		if(user) {
			let token = this.jwtHelperService.decodeToken(user.token);
			if(token && token.userLanguage && token.userLanguage.isoCode) {
				return token.userLanguage.isoCode;
			} else {
				return 'en';
			}
		} else {
			return 'en';
		}
	}

	getAllUsers(pageNumber: number, pageSize: number, sortDirection: string, sortKey: string): Observable<User[]> {
		const params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString())
			.set('sortDirection', sortDirection)
			.set('sortKey', sortKey);
		return this.apiService.get(routes.users, params).pipe(map(res => <User[]> res));
	}

	getUserById(id: number): Observable<User> {
		return this.apiService.get(routes.users + id).pipe(map(res => <User> res));
	}

	saveUser(body) {
		return this.apiService.post(routes.users, body);
	}

	getAllLanguages(): Observable<Language[]> {
		return this.apiService.get(routes.getAllLanguages).pipe(map(res => <Language[]> res));
	}

	getAllRoles(): Observable<Role[]> {
		return this.apiService.get(routes.getAllRoles).pipe(map(res => <Role[]> res));
	}

	deleteUserById(id: number): Observable<any> {
		return this.apiService.delete(routes.users + id);
	}

	getProfileUser(): Observable<User> {
		return this.apiService.get(routes.userProfile).pipe(map(res => <User> res));
	}

	saveProfileUser(body) {
		return this.apiService.post(routes.userProfile, body);
	}

	getAllNotificationEvents(): Observable<NotificationEvent[]> {
		return this.apiService.get(routes.getAllNotificationEvents).pipe(map(res => <NotificationEvent[]> res));
	}

	getAllNotificationScopes(): Observable<NotificationScope[]> {
		return this.apiService.get(routes.getAllNotificationScopes).pipe(map(res => <NotificationScope[]> res));
	}

	verifyNewEmailAddress(token: string): Observable<any> {
		const params = new HttpParams()
			.set('token', token);
		return this.apiService.get(routes.verifyNewEmailAddress, params);
	}

	authorizeUser(response_type: string, client_id: string, redirect_uri: string, scope: string, state: string): Observable<string> {
		const params = new HttpParams()
			.set('response_type', response_type)
			.set('client_id', client_id)
			.set('redirect_uri', redirect_uri)
			.set('scope', scope)
			.set('state', state);
		return this.apiService.get(routes.authorize, params);
	}
}
