import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { UserService } from "@app/core/services/user.service";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AlertDialogComponent } from "@app/shared/components/alert-dialog/alert-dialog.component";
import { MatDialog } from "@angular/material";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private userService: UserService,
		private router: Router,
		private dialog: MatDialog
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let token = this.userService.getToken();
		if (token && token != "") {
			if (
				request.url.indexOf("amazonaws.com") === -1 &&
				request.url.indexOf("download-attachment") === -1
			) {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
			}
		}

		return next.handle(request).pipe(
			map(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						//console.log('event--->>>', event);
					}
					return event;
				},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401 || err.status === 403) {
							this.router.navigate(["login"]);
						} else if (
							err.error != undefined &&
							err.error != null &&
							err.error.localizedMessage != undefined &&
							err.error.localizedMessage != ""
						) {
							this.dialog.open(AlertDialogComponent, {
								width: "450px",
								data: {
									title: "Server Error",
									text: err.error.localizedMessage,
									content: null,
								},
							});
						}
					}
				}
			),
			catchError((err: HttpErrorResponse) => {
				if (err.status === 401 || err.status === 403) {
					this.router.navigate(["login"]);
				} else if (
					err.error != undefined &&
					err.error != null &&
					err.error.localizedMessage != undefined &&
					err.error.localizedMessage != ""
				) {
					this.dialog.open(AlertDialogComponent, {
						width: "450px",
						data: {
							title: "Server Error",
							text: err.error.localizedMessage,
							content: null,
						},
					});
				}
				return throwError(err);
			})
		);
	}
}
