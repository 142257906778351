import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Country } from '@app/core/models/country.model';
import { map } from 'rxjs/operators';

const routes = {
	countries: 'countries/', 
};

@Injectable({
  providedIn: 'root'
})
export class CountryService {
	jwtHelperService: JwtHelperService = new JwtHelperService();

	constructor(
		private apiService: ApiService, 
	) {	}

	getAllCountries(): Observable<Country[]> {
		const params = new HttpParams()
			.set('pageNumber', '0')
			.set('pageSize', '100000000')
			.set('sortDirection', 'asc')
			.set('sortKey', 'name');
		return this.apiService.get(routes.countries, params).pipe(map(res => <Country[]> res['content']));
	}

	getAllCountriesPage(pageNumber: number, pageSize: number, sortDirection: string, sortKey: string): Observable<Country[]> {
		const params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString())
			.set('sortDirection', sortDirection)
			.set('sortKey', sortKey);
		return this.apiService.get(routes.countries, params).pipe(map(res => <Country[]> res));
	}

	getCountryById(id: number): Observable<Country> {
		return this.apiService.get(routes.countries + id).pipe(map(res => <Country> res));
	}

	saveCountry(body) {
		return this.apiService.post(routes.countries, body);
	}

	deleteCountryById(id: number): Observable<any> {
		return this.apiService.delete(routes.countries + id);
	}
}
