import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment as env } from "environments/environment";

const BASE_URL = env.baseUrl;

export interface Filter {
	pageNumber: number;
	pageSize: number;
	sortKey: string;
	sortDirection: string;
}

@Injectable({
	providedIn: "root",
})
export class ApiService {
	private options = { headers: new HttpHeaders().set("Content-Type", "application/json") };

	constructor(private http: HttpClient) {}

	public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.get(BASE_URL + path, { params }).pipe(catchError(this.handleError));
	}

	public put(path: string, body: object = {}): Observable<any> {
		return this.http.put(BASE_URL + path, JSON.stringify(body), this.options).pipe(catchError(this.handleError));
	}

	public post(path: string, body: object = {}): Observable<any> {
		return this.http.post(BASE_URL + path, JSON.stringify(body), this.options).pipe(catchError(this.handleError));
	}

	public delete(path: string): Observable<any> {
		return this.http.delete(BASE_URL + path).pipe(catchError(this.handleError));
	}

	public deletePost(path: string, body: object = {}): Observable<any> {
		return this.http.request("DELETE", BASE_URL + path, { headers: this.options.headers, body: body }).pipe(catchError(this.handleError));
	}

	public handleError(error: any): Observable<any> {
		return throwError(error.error);
	}

	setFilters(filterParams: Filter) {
		return `pageNumber=${filterParams.pageNumber}&pageSize=${filterParams.pageSize}&sortDirection=${filterParams.sortDirection}&sortKey=${filterParams.sortKey}`;
	}
}
