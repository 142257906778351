import { Component, OnInit, Inject } from '@angular/core';
import { MapLocation } from '@app/core/models/map-location.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare let L;

@Component({
	selector: 'app-merits-map-dialog',
	templateUrl: './merits-map-dialog.component.html',
	styleUrls: ['./merits-map-dialog.component.scss']
})
export class MeritsMapDialogComponent implements OnInit {
	
	constructor(
		public dialogRef: MatDialogRef<MeritsMapDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MapLocation
	) { }
		
	ngOnInit() {
		//var baselayer = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		//  	maxZoom: 18,
		//	attribution: 'Map data &copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		//}).addTo(map);
		
		var map = L.map('map').setView([this.data.gpsLatitude, this.data.gpsLongitude], 13);
		//var mytile = new L.TileLayer.WMS("https://tsga.maps.cet4biz.com/cgi-bin/project/qgis_mapserv.fcgi", {
		//	map: "TSGA_Services_GIS.qgs",
      	//	layers: 'countries,regions,landuse,water,roads,buildings,railways,places',
		//	format: 'image/jpeg',
		//	bgcolor: '0xA3D9FF',
   		//	tileSize: 1300
		//}).addTo(map);

		var transport = L.tileLayer('https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=4d38a68773824641ba3cbdf73bbb8f30', {
			
		}).addTo(map);
		
		var geojsonFeature = {
			"type": "Feature",
			"properties": {
				"locationName": this.data.locationName,
				"locationCode": this.data.locationCode,
				"locationType": this.data.locationType
			},
			"geometry": {
				"type": "Point",
				"coordinates": [this.data.gpsLongitude, this.data.gpsLatitude]
			}
		};
		
		var tsgaObjects = L.geoJSON(geojsonFeature, {
			onEachFeature: function(feature, layer): void {
				var popupContent = "<strong>" + feature.properties.locationName + "</strong><br />";
				popupContent += feature.properties.locationCode + "<br />";
				if(feature.properties.locationType !== undefined){
					popupContent += feature.properties.locationType;
				}
				layer.bindPopup(popupContent);
			}, 
			pointToLayer: function (feature, latlng) {
				return L.circleMarker(latlng, {
					radius: 8,
					fillColor: "#ff7800",
					color: "#000",
					weight: 1,
					opacity: 1,
					fillOpacity: 0.8
				});
			}
		}).addTo(map);
		
		L.control.layers({'Transport': transport}, {'TSGA objects': tsgaObjects}).addTo(map);
	}
	
	onNoClick(): void {
		this.dialogRef.close();
	}

}
